// News

.page-link {
    font-weight: $font-weight-bold;
}

.news-list-view {
    margin-top: 1.5rem;

    @include media-breakpoint-up(lg){
        margin-top: 2rem;
    }

    @include media-breakpoint-up(xl){
        margin-top: 4rem;
    }

    .card-img-top {
        a {
            img {
                transition: all 0.4s;
            }

            &:hover {
                img {
                    transform: scale(1.05);
                }
            }
        }

    }
}
