.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #914c12;
  background-color: #fff;
  border: 1px solid #e9e9e9;
}

.page-link:hover {
  z-index: 2;
  color: #824005;
  text-decoration: none;
  background-color: #f6f6f6;
  border-color: #e9e9e9;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 76, 18, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #914c12;
  border-color: #914c12;
}

.page-item.disabled .page-link {
  color: #7c7c7c;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e9e9e9;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.17188rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.page-link {
  font-weight: 700;
}

.news-list-view {
  margin-top: 1.5rem;
}

@media (min-width: 992px) {
  .news-list-view {
    margin-top: 2rem;
  }
}

@media (min-width: 1420px) {
  .news-list-view {
    margin-top: 4rem;
  }
}

.news-list-view .card-img-top a img {
  transition: all 0.4s;
}

.news-list-view .card-img-top a:hover img {
  transform: scale(1.05);
}
